<template>
  <div>
    <Card>
      <Row style="text-align: left">
        <Col span="2">
          <Button type="primary" ghost @click="createRole"><Icon type="ios-add" />添加角色</Button>
        </Col>
        <Col span="2">
          <Button type="error" ghost @click="deleteRole" v-if="isShowDelBtn"><Icon type="ios-trash-outline" />删除角色</Button>
        </Col>
      </Row>
    </Card>
    <div>
      <Table border ref="selection" :columns="columns" :data="data" @on-selection-change="selected" @on-select-all="selectAll" @on-select-all-cancel="selectAllCancel"></Table>
    </div>
    <base-drawer :is-show="drawer.isShow" :title="drawer.title" @closeDrawer="closeDrawer" @submit="submit">
      <Form ref='roleForm' :model="formData">
        <Row :gutter="32">
          <Col span="12">
            <FormItem label="角色名称" prop="role" label-position="top">
              <Input v-model="formData.role" placeholder="角色名称" />
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="角色说明" prop="comment" label-position="top">
              <Input v-model="formData.comment" placeholder="角色说明" />
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="12">
            <FormItem label="是否默认角色" prop="default" label-position="top">
              <Select v-model.number="formData.default" placeholder="是否默认角色">
                <Option :value="0">否</Option>
                <Option :value="1">是</Option>
              </Select>
            </FormItem>
          </Col>
        </Row>
      </Form>
    </base-drawer>
    <base-drawer :is-show="drawer.isShowMenuTree" :title="drawer.title" @closeDrawer="closeDrawer" @submit="submit">
      <Tree ref="menuTreeData" :data="menuTreeData" @on-check-change="checkChange" show-checkbox></Tree>
    </base-drawer>
  </div>
</template>

<script>
import BaseDrawer from "@/components/base/baseDrawer";
import Notice from "@/components/baseNotice.js";
import {del, get, post, put} from "@/api/http";

export default {
  name: "role",
  data () {
    return {
      isEdit: false,
      drawer:{
        isShow: false,
        title: '',
        isShowMenuTree: false,
      },
      formData: {
        role: '',
        comment: '',
        default: 0
      },
      menuTreeData: [],
      selectedRole: [],
      columns: [
        {
          type: 'selection',
          width: 60,
          align: 'center'
        },
        {
          title: '角色名称',
          key: 'role',
          render: (h, {row}) => {
            return h('div',[
              h("Avatar", {
                style: {
                  marginRight: '5px'
                },
                props: {
                  src: 'https://i.loli.net/2017/08/21/599a521472424.jpg'
                },
              }, row.role),
              h("span", row.role),
            ])
          }
        },
        {
          title: '角色说明',
          key: 'comment'
        },
        {
          title: '默认',
          key: 'status',
          width: 100,
          render: (h, {row}) => {
            let statusColor = {
              "0": ['否', '#ed4014'],
              "1": ['是', '#19be6b']
            }
            return h('Badge',{
              props: {
                color: statusColor[row.default][1],
                text:  statusColor[row.default][0]
              },
            });
          }
        },
        {
          title: '添加时间',
          key: 'CreatedAt'
        },
        {
          title: '操 作',
          key: 'action',
          width: 150,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h('a', {
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.menuPer(params.row)
                  }
                }
              }, '菜单权限 |'),
              h('a', {
                on: {
                  click: () => {
                    this.updateRole(params.index)
                  }
                }
              }, '编 辑')
            ]);
          }
        }
      ],
      data: []
    }
  },
  components: {
    BaseDrawer
  },
  methods: {
    renderContent(h, { root, node, data }){
      return h('span', {
        style: {
          display: 'inline-block',
          width: '100%'
        }
      }, [
        h('span', [
          h('Icon', {
            props: {
              type: 'ios-paper-outline'
            },
            style: {
              marginRight: '8px'
            }
          }),
          h('span', data.title)
        ]),
        h('span', {
          style: {
            display: 'inline-block',
            float: 'right',
            marginRight: '32px'
          }
        }, [
          h('Checkbox', {
            props: Object.assign({}, {}, {
            icon: 'ios-remove'
          }),
          on: {
            click: () => { this.remove(root, node, data) }
          }
          }, "全选"),
          h('Checkbox', {
            props: Object.assign({}, {}, {
              icon: 'ios-add'
            }),
            style: {
              marginRight: '8px'
            },
            on: {
              click: () => { this.append(data) }
            }
          }, "读"),
          h('Checkbox', {
            props: Object.assign({}, {}, {
              icon: 'ios-remove'
            }),
            on: {
              click: () => { this.remove(root, node, data) }
            }
          }, "写"),
          h('Checkbox', {
            props: Object.assign({}, {}, {
              icon: 'ios-remove'
            }),
            on: {
              click: () => { this.remove(root, node, data) }
            }
          }, "读写")
        ])
      ]);
    },
    getRole() {
      get("/api/v1/role", "")
          .then((resp)=>{
            this.data = resp.data;
          }).catch(e=>{
            return e
      })
    },
    createRole() {
      this.isEdit = false;
      this.drawer.title = "添加角色";
      this.resetForm()
      this.drawer.isShow = true;
    },
    deleteRole() {
      let roleId = this.selectedRole.map(e => e.id);
      del("/api/v1/role", {"id": roleId})
          .then(()=>{
            this.getRole();
            Notice.success("删除操作", "删除成功")
          })
          .catch((e)=>{
            return e
          })
    },
    updateRole (index) {
      this.isEdit = true;
      this.drawer.title = "编辑角色";
      this.drawer.isShow = true;
      this.formData = this.$_.cloneDeep(this.data[index]);
    },
    submit() {
      this.drawer.isShow = false;
      this.drawer.isShowMenuTree = false;
      let postData = this.$_.cloneDeep(this.formData);
      // postData.roles = this.drawer.roles.filter((e) => this.$_.includes(this.formData.roles, e.id))
      if (this.isEdit) {
        put("/api/v1/role", postData)
            .then(()=>{
              this.getRole();
              Notice.success("更新操作", "更新成功")
            })
            .catch((e)=>{
             return e
            })
      } else {
        post("/api/v1/role", postData)
            .then(()=>{
              this.getRole();
              Notice.success("提交操作", "添加成功")
            })
            .catch((e)=>{
              return e
            })
      }
    },
    menuPer(data) {
      this.isEdit = true;
      this.drawer.title = "菜单权限";
      this.drawer.isShowMenuTree = true;
      this.formData = this.$_.cloneDeep(data)
      let menuIds = data.menus.map((item)=>{
        return item.id;
      })
      this.menuTreeData = this.$_.sortBy(this.parseMenu(this.menuTreeData, menuIds), 'index')
    },
    parseMenu(menuTree, menuIds){
      menuTree.forEach((item)=>{
        if (menuIds.indexOf(item.id) > -1 && item.pid !== 0 &&  item.children.length === 0) {
          this.$set(item, "checked", true);
        } else {
          this.$set(item, "checked", false);
        }

        if (item.children && item.children.length > 0) {
          this.parseMenu.call(this, item.children, menuIds)
        }
      })
      console.log(menuTree)
      return menuTree
    },
    checkChange(nodes) {
      this.formData.menus = this.$refs.menuTreeData.getCheckedAndIndeterminateNodes();
    },
    closeDrawer() {
      this.drawer.isShowMenuTree = false;
      this.drawer.isShow = false;
    },
    selected(selection) {
      this.selectedRole = selection;
    },
    selectAll(selection) {
      this.selectedRole = selection;
    },
    selectAllCancel(selection) {
      this.selectedRole = selection;
    },
    getMenus(){
      get("/api/v1/menu", "").then((resp)=>{
        this.menuTreeData = resp.data;
      })
    },
    resetForm() {
      this.formData = {
        role: '',
        comment: '',
        default: 0
      }
    }
  },
  computed: {
    isShowDelBtn() {
      return this.selectedRole.length > 0;
    }
  },
  created() {
    this.getMenus();
    this.getRole();
  }
}
</script>

<style scoped>
</style>